html {
  position: relative;
  min-height: 100%;
  padding-bottom: 120px;
  box-sizing: border-box;
}

body {
  color: #4d4d4d;
  overflow-x: hidden;
}

footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 120px;
}

.footer-text {
  text-decoration: none;
  color: #6C757D;
}


.footer-text:hover {
  color: #343A40;
}


.user-icon {
  width: 130px;
  height: 130px;
}

.input-wrap{
  position: relative;
}
   
.toggle-pass{
  position:absolute;
  top:50%;
  right: 10px;
  transform: translateY(-50%);
}

.grecaptcha-badge {
  visibility: hidden;
}

/* -------------------------------------------------------- */

/* .App {
  text-align: center;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.icon_and_name {
  width: 150px;
  height: 150px;
  /* position: relative; */
  position: absolute;
  top: 310px;
  left: 14%;
}

.right {
  float: right;
  margin: 10px;
}

.button_link{
  text-decoration: none;
  font-size: 13px;
}

.emblem {
  width: 30px;
  height: 30px;
}

.home_tag {
  background-color: orangered;
}

.carousel-control-prev-icon {
    background-color: gray;
}
.carousel-control-next-icon {
    background-color: gray;
}

#page_top {
  width: 50px;
  height: 50px;
  position: fixed;
  right: 10px;
  bottom: 10px;
  background: #6c757d;
  opacity: 0.6;
  border-radius: 50%;
}

#page_top a {
  position: relative;
  display: block;
  width: 50px;
  height: 50px;
  text-decoration: none;
}

.heatmap-cell {
  width: 30px;
  height: 30px;
  margin-top: 13px;
  margin-bottom: 13px;
  margin-left: 10px;
  margin-right: 10px;
}
@media screen and (max-width: 1024px) {
  .heatmap-cell {
    width: 30px;
    height: 30px;
    margin-top: 13px;
    margin-bottom: 13px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media screen and (max-width: 896px) {
  .heatmap-cell {
    width: 25px;
    height: 25px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 7px;
    margin-right: 7px;
  }
}
@media screen and (max-width: 480px) {
  .heatmap-cell {
    width: 20px;
    height: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 3.5px;
    margin-right: 3.5px;
  }
}